body {
  margin: 0;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS for sweetalerts */
.swal-text {
  text-align: center;
  line-height: 1.5;
}

.swal-button--confirm {
  background-color: #008640;
}

.swal-button--cancel {
  background-color: #ef8a6f;
}